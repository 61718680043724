.be-notifications {
	padding-top: 0;

	.float-right & {
		left: auto;
		right: $grid-gutter-width - 5px;
	}

	.be-scroller-notifications {
		position: relative;
		height: 222px;

		> .content {

			> ul{
				list-style: none;
				padding-left: 0;
			}
		}
	}

	.notification {
		overflow: hidden;
		border-bottom: 1px solid darken(theme-color("light"), 7.7%);

		> a {
			padding: 15px;
			display: block;
			overflow: hidden;

			.image {
				height: 38px;
				width: 38px;
				border-radius: 50%;
				background-color: darken(theme-color("light"), 10.5%);
				float: left;												
				text-align: center;
				overflow: hidden;

				img {
					height: 38px;
					width: 38px;
					font-size: 1.923rem;
					color: lighten(theme-color("dark"), 35%);
					line-height: 36px;
				}
			}

			.notification-info {
				padding-left: 50px;
				line-height: 14px;

				.text {
					font-weight: 500;
					color: darken(theme-color("light"), 45.7%);
					font-size: .9231rem;
					line-height: 16px;
				}

				.user-name {
					color: $link-color;
					font-size: 1rem;
				}

				.date {
					color: darken(theme-color("light"), 45.7%);
					display: block;
					font-size: .6923rem;
					margin-top: 4px;
					text-transform: uppercase;
				}
			}
		}

		&.notification-unread {
			background-color: lighten(theme-color("primary"), 35%);
			border-bottom: 1px solid lighten(theme-color("primary"), 32%);

			> a {
				position: relative;

				&:after {
					content: '';
					display: block;
					position: absolute;
					right: 15px;
					top: 20px;
					width: 6px;
					height: 6px;
					background-color: theme-color("primary");
					border-radius: 50%;
				}
			}
		}

		&:hover {
			background-color: theme-color("primary");

			> a {
				color: theme-color("light");

				.logo {
					background-color: darken(theme-color("primary"), 7%);

					.icon {
						color: theme-color("light");
					}
				}

				.notification-info {

					.circle {
						background: theme-color("light");
					}

					.text, .date, .user-name {
						color: theme-color("light");
					}
				}

				&:after {
					background-color: theme-color("light");
				}
			}
		}

		&:last-child {
			border-bottom: 0;
		}
	}
}

//Phone resolution
@include phone {

	.be-notifications {

		.navbar-nav .show & {
			position: absolute;
			background-color: theme-color("light")
		}
	}
}

@include phone {

	.be-notifications {

		.navbar-nav .show & {
			position: fixed;
		}
	}
}